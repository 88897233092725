import React, { useState } from 'react';
import { Link } from "react-router-dom";
import "../assets/css/common-bundle.css@ts=20240226213632.css";
import "../assets/css/a188dd9953ad02b29cac21d71f3f9141-bundle.css@ts=20240226213632.css";
import "../App.css";
function Main() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div id="wb_root" className="root wb-layout-vertical">
      <div className="wb_sbg"></div>
      <div
        id="wb_header_a188dd9953ad02b29cac21d71f3f9141"
        className="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div className="wb_content wb-layout-vertical">
          <div
            id="a188dd9940c438fe407d1fea0abe36f6"
            className="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div className="wb_content wb-layout-horizontal">
              <div
                id="a188dd9940c4398c77b5c63b6e6b60fd"
                className="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div className="wb_content wb-layout-horizontal">
                  <div
                    id="a188dd9940c43a26af62d556ad5ffff4"
                    className="wb_element wb_text_element"
                    data-plugin="TextArea"
                    style={{ lineHeight: "normal" }}
                  >
                    <h5
                      className="wb-stl-subtitle"
                      style={{ textAlign: "center" }}
                    >
                      Captain Crytpo
                    </h5>
                  </div>
                </div>
              </div>
              <div
                id="a18b6705100e00761195196dd80c0372"
                className="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div className="wb_content wb-layout-vertical">
                  <div
                    id="a188dd9940c43bbe64de61a09b7ba7b4"
                    className="wb_element wb-menu wb-prevent-layout-click wb-menu-mobile"
                    data-plugin="Menu"
                  >
                    <button className="btn btn-default btn-collapser" onClick={toggleMenu}>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                    <ul className={`hmenu ${isMenuOpen ? 'open' : ''}`} dir="ltr">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="">
                        <Link to="/Our-Products">Our Products</Link>
                      </li>
                      <li className="">
                        <Link to="/About-Us">About Us</Link>
                      </li>
                      <li className="">
                        <Link to="/Privacy-Policy">Privacy Policy</Link>
                      </li>
                      <li className="">
                        <Link to="/Contact-Us" className='colortext'>Contact Us</Link>
                      </li>
                    </ul>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="wb_main_a188dd9953ad02b29cac21d71f3f9141"
        className="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div className="wb_content wb-layout-vertical">
          <div
            id="a188dd9940c43dd3027b8f0fdcdc8c58"
            className="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div className="wb_content wb-layout-vertical"></div>
          </div>
          <div
            id="a18ddcafed38007ad344592595d4d261"
            className="wb_element wb_text_element wb-anim-entry wb-anim wb-anim-fade-in-none"
            data-plugin="TextArea"
            data-wb-anim-entry-time="0.6"
            data-wb-anim-entry-delay="0"
            style={{ lineHeight: "normal" }}
          >
            <h2 className="wb-stl-heading2">
              <span style={{ color: "rgba(8, 226, 255, 1)" }}>Contact Us</span>
            </h2>
          </div>
          <div
            id="a18ddcaefc7800377755d1c97e91c30a"
            className="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div className="wb_content wb-layout-horizontal">
              <div
                id="a18ddcaefc9c00e12703c6aa09800134"
                className="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div className="wb_content wb-layout-horizontal">
                  <div
                    id="a18ddcaefcc000240021528f36ae2798"
                    className="wb_element"
                    data-plugin="Form"
                  >
                    <form
                      id="a18ddcaefcc000240021528f36ae2798_form"
                      className="wb_form wb_mob_form wb_form_ltr wb_form_vertical"
                      method="post"
                      encType="multipart/form-data"
                      action="/Contact-Us"
                    >
                      <input type="hidden" name="wb_form_id" value="78240d80" />
                      <input
                        type="hidden"
                        name="wb_form_uuid"
                        value="2662994c"
                      />
                      <textarea
                        name="message"
                        rows="3"
                        cols="20"
                        className="hpc"
                        autoComplete="off"
                      ></textarea>
                      <table>
                        <tr>
                          <th>
                            Name<span className="text-danger">&nbsp;*</span>
                          </th>
                          <td>
                            <input
                              type="hidden"
                              name="wb_input_0"
                              value="Name"
                            />
                            <div>
                              <input
                                className="form-control form-field"
                                type="text"
                                defaultValue=""
                                placeholder=""
                                maxLength="255"
                                name="wb_input_0"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            E-mail<span className="text-danger">&nbsp;*</span>
                          </th>
                          <td>
                            <input
                              type="hidden"
                              name="wb_input_1"
                              value="E-mail"
                            />
                            <div>
                              <input
                                className="form-control form-field"
                                type="email"
                                defaultValue=""
                                placeholder=""
                                name="wb_input_1"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            How did you find us?
                            <span className="text-danger">&nbsp;*</span>
                          </th>
                          <td>
                            <input
                              type="hidden"
                              name="wb_input_2"
                              value="How did you find us?"
                            />
                            <div>
                              <select
                                className="form-control form-field"
                                name="wb_input_2"
                                required
                              >
                                <option disabled selected>Please Select</option>
                                <option value="0">Found on google</option>
                                <option value="1">
                                  Followed link from other site
                                </option>
                                <option value="2">Friend told me</option>
                              </select>
                            </div>
                          </td>
                        </tr>
                        <tr className="area-row">
                          <th>
                            Message<span className="text-danger">&nbsp;*</span>
                          </th>
                          <td>
                            <input
                              type="hidden"
                              name="wb_input_3"
                              value="Message"
                            />
                            <div>
                              <textarea
                                className="form-control form-field form-area-field"
                                rows="4"
                                placeholder=""
                                name="wb_input_3"
                                required
                              ></textarea>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th></th>
                          <td>
                            <div>
                              <label className="checkbox-label">
                                <input
                                  className="form-control form-field"
                                  type="checkbox"
                                  value="1"
                                  name="wb_input_4"
                                />
                                <p>I want to receive newsletters</p>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th></th>
                          <td>
                            <div>
                              <label className="checkbox-label">
                                <input
                                  className="form-control form-field"
                                  type="checkbox"
                                  value="1"
                                  name="wb_input_5"
                                  required
                                />
                                <p>I agree to the Terms and Conditions</p>
                                <span className="text-danger">&nbsp;*</span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr className="form-footer">
                          <td colspan="2" className="text-right">
                            <button type="submit" className="btn btn-default">
                              <span>Submit</span>
                            </button>
                          </td>
                        </tr>
                      </table>
                    </form>
                    <script type="text/javascript"></script>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="wb_footer_a188dd9953ad02b29cac21d71f3f9141"
        className="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div className="wb_content wb-layout-vertical">
          <div
            id="a188dd9940c445fb48de0951260bf6da"
            className="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div className="wb_content wb-layout-vertical">
              <div
                id="a188dd9940c446b32be80ffd00af97c3"
                className="wb_element wb_text_element"
                data-plugin="TextArea"
                style={{ lineHeight: "normal" }}
              >
                <p>
                  <span style={{ color: "#ffffff" }}>
                    <span style={{ fontSize: "10px" }}>Copyright</span>{" "}
                    <span style={{ fontSize: "10px" }}>
                      2024 captaincrytpo.com - All Rights Reserved
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            id="wb_footer_c"
            className="wb_element"
            data-plugin="WB_Footer"
            style={{ textAlign: "center", width: "100%" }}
          >
            <div className="wb_footer"></div>
            <script type="text/javascript">
              {`
                $(function () {
                  var footer = $(".wb_footer");
                  var html = (footer.html() + "").replace(/^\s+|\s+$/g, "");
                  if (!html) {
                    footer.parent().remove();
                    footer = $("#footer, #footer .wb_cont_inner");
                    footer.css({ height: "" });
                  }
                });
              `}
            </script>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
