import { Route,Routes } from 'react-router-dom';
import Main from './components/Main';
import Contact from './components/Contact';
import About from './components/About';
import Privacy from './components/Privacy';
import Products from './components/Products';
function App() {
  return (
    <Routes>
      <Route index element={<Main />}></Route>
      <Route path='/Our-Products' element={<Products />}></Route>
      <Route path='/About-Us' element={<About />}></Route>
      <Route path='/Privacy-Policy' element={<Privacy />}></Route>
      <Route path='/Contact-Us' element={<Contact />}></Route>
    </Routes>
  );
}

export default App;
