import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../assets/css/common-bundle.css@ts=20240226213632.css";
import "../assets/css/a188dd9953ad00e73fee847744918c2e-bundle.css@ts=20240226213632.css";
import "../App.css";
// import link from "../assets/file/app-debug.apk";

function Main() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/file/app-release.apk";
    link.download = "app-release.apk";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div id="wb_root" className="root wb-layout-vertical">
      <div className="wb_sbg"></div>
      <div
        id="wb_header_a188dd9953ad00e73fee847744918c2e"
        className="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div className="wb_content wb-layout-vertical">
          <div
            id="a188dd9940c438fe407d1fea0abe36f6"
            className="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div className="wb_content wb-layout-horizontal">
              <div
                id="a188dd9940c4398c77b5c63b6e6b60fd"
                className="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div className="wb_content wb-layout-horizontal">
                  <div
                    id="a188dd9940c43a26af62d556ad5ffff4"
                    className="wb_element wb_text_element"
                    data-plugin="TextArea"
                    style={{ lineHeight: "normal" }}
                  >
                    <h5
                      className="wb-stl-subtitle"
                      style={{ textAlign: "center" }}
                    >
                      Captain Crytpo
                    </h5>
                  </div>
                </div>
              </div>
              <div
                id="a18b6705100e00761195196dd80c0372"
                className="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div className="wb_content wb-layout-vertical">
                  <div
                    id="a188dd9940c43bbe64de61a09b7ba7b4"
                    className="wb_element wb-menu wb-prevent-layout-click wb-menu-mobile"
                    data-plugin="Menu"
                  >
                    <button
                      className="btn btn-default btn-collapser"
                      onClick={toggleMenu}
                    >
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                    <ul
                      className={`hmenu ${isMenuOpen ? "open" : ""}`}
                      dir="ltr"
                    >
                      <li>
                        <Link to="/" className="colortext">
                          Home
                        </Link>
                      </li>
                      <li className="">
                        <Link to="/Our-Products">Our Products</Link>
                      </li>
                      <li className="">
                        <Link to="/About-Us">About Us</Link>
                      </li>
                      <li className="">
                        <Link to="/Privacy-Policy">Privacy Policy</Link>
                      </li>
                      <li className="">
                        <Link to="/Contact-Us">Contact Us</Link>
                      </li>
                    </ul>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="wb_main_a188dd9953ad00e73fee847744918c2e"
        className="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div className="wb_content wb-layout-vertical">
          <div
            id="a18ddcac42cd006a3660a491bcbf94af"
            className="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div className="wb_content wb-layout-horizontal">
              <div
                id="a18ddc8e4e9f004fa84431edc41186f0"
                className="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div className="wb_content wb-layout-vertical">
                  <div
                    id="a18ddc956aa700deb7e07f0c0c15705b"
                    className="wb_element wb_text_element"
                    data-plugin="TextArea"
                    style={{ lineHeight: "normal", width: "90%" }}
                  >
                    <p className="wb-stl-normal" style={{ textAlign: "right" }}>
                      <button
                        className="download-button"
                        onClick={handleDownload}
                      >
                        Download APP
                      </button>
                    </p>

                    <p
                      className="wb-stl-normal"
                      style={{ textAlign: "center" }}
                    >
                       
                    </p>

                    <p
                      className="wb-stl-normal"
                      style={{ textAlign: "center" }}
                    >
                       
                    </p>

                    <p
                      className="wb-stl-normal"
                      style={{ textAlign: "center" }}
                    >
                       
                    </p>

                    <p
                      className="wb-stl-normal"
                      style={{ textAlign: "center" }}
                    >
                       
                    </p>

                    <p
                      className="wb-stl-normal"
                      style={{ textAlign: "center" }}
                    >
                      <span style={{ fontSize: "72px" }}>
                        <span style={{ color: "#ffffff" }}>Captain Crytpo</span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="wb_footer_a188dd9953ad00e73fee847744918c2e"
        className="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div className="wb_content wb-layout-vertical">
          <div
            id="a188dd9940c445fb48de0951260bf6da"
            className="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div className="wb_content wb-layout-vertical">
              <div
                id="a188dd9940c446b32be80ffd00af97c3"
                className="wb_element wb_text_element"
                data-plugin="TextArea"
                style={{ lineHeight: "normal" }}
              >
                <p>
                  <span style={{ color: "#ffffff" }}>
                    <span style={{ fontSize: "10px" }}>Copyright</span> 
                    <span style={{ fontSize: "10px" }}>
                      2024 captaincrytpo.com - All Rights Reserved
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            id="wb_footer_c"
            className="wb_element"
            data-plugin="WB_Footer"
            style={{ textAlign: "center", width: "100%" }}
          >
            <div className="wb_footer"></div>
            <script type="text/javascript">
              {`$(function () {
                var footer = $(".wb_footer");
                var html = (footer.html() + "").replace(/^\s+|\s+$/g, "");
                if (!html) {
                  footer.parent().remove();
                  footer = $("#footer, #footer .wb_cont_inner");
                  footer.css({ height: "" });
                }
              });`}
            </script>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
