import React, { useState } from 'react';
import { Link } from "react-router-dom";
import "../assets/css/common-bundle.css@ts=20240226213632.css";
import "../assets/css/a18de6db260a00a166259f58cc4e2d52-bundle.css@ts=20240226213632.css";
import "../App.css";
function Privacy() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div id="wb_root" className="root wb-layout-vertical">
      <div className="wb_sbg"></div>
      <div
        id="wb_header_a18de6db260a00a166259f58cc4e2d52"
        className="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div className="wb_content wb-layout-vertical">
          <div
            id="a188dd9940c438fe407d1fea0abe36f6"
            className="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div className="wb_content wb-layout-horizontal">
              <div
                id="a188dd9940c4398c77b5c63b6e6b60fd"
                className="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div className="wb_content wb-layout-horizontal">
                  <div
                    id="a188dd9940c43a26af62d556ad5ffff4"
                    className="wb_element wb_text_element"
                    data-plugin="TextArea"
                    style={{ lineHeight: "normal", textAlign: "center" }}
                  >
                    <h5 className="wb-stl-subtitle">Captain Crytpo</h5>
                  </div>
                </div>
              </div>
              <div
                id="a18b6705100e00761195196dd80c0372"
                className="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div className="wb_content wb-layout-vertical">
                  <div
                    id="a188dd9940c43bbe64de61a09b7ba7b4"
                    className="wb_element wb-menu wb-prevent-layout-click wb-menu-mobile"
                    data-plugin="Menu"
                  >
                    <button className="btn btn-default btn-collapser" onClick={toggleMenu}>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                    <ul className={`hmenu ${isMenuOpen ? 'open' : ''}`} dir="ltr">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="">
                        <Link to="/Our-Products">Our Products</Link>
                      </li>
                      <li className="">
                        <Link to="/About-Us">About Us</Link>
                      </li>
                      <li className='colortext'>
                        <Link to="/Privacy-Policy" className='colortext'>Privacy Policy</Link>
                      </li>
                      <li className="">
                        <Link to="/Contact-Us">Contact Us</Link>
                      </li>
                    </ul>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="wb_main_a18de6db260a00a166259f58cc4e2d52"
        className="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div className="wb_content wb-layout-vertical">
          <div
            id="a18de6db278400b59222362fb7e0d5f3"
            className="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div className="wb_content wb-layout-horizontal">
              <div
                id="a18de6db2784014c4026e1e5952f806c"
                className="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div className="wb_content wb-layout-vertical"></div>
              </div>
              <div
                id="a18de6db278503ab89491f97ff12cf64"
                className="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div className="wb_content wb-layout-vertical">
                  <div
                    id="a18de6db2785008e7c310cfe7df253ca"
                    className="wb_element wb_text_element"
                    data-plugin="TextArea"
                    style={{ lineHeight: "normal" }}
                  >
                    <h5 className="wb-stl-subtitle">Privacy Policy</h5>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>
                      <br />
                      <font color="#ffffff">
                        CaptainCrytpo.com  is the exclusive property of Captain
                        Crytpo Technologies.
                      </font>
                    </p>

                    <p>
                      <font color="#ffffff">
                         <br />1 in 100 is the exclusive property of Captain
                        Crytpo Technologies.
                      </font>
                    </p>

                    <p>
                      <font color="#ffffff">
                         <br />
                        This app will never be used to collect any user
                        information or track and sell user information. Data
                        will not be automatically collected and processed by us
                        or our partners. Data will not be shared with additional
                        third parties. Confidentiality and privacy are very
                        important to us, and we will respect yours.
                      </font>
                    </p>
                    <p>
                      <font color="#ffffff">
                         <br />
                        Usage of our apps and servers may be logged and
                        processed for internal statistical use. We do not
                        knowingly collect any Personal Identifiable Information
                        from the users of our apps.
                      </font>
                    </p>
                    <p>
                      <font color="#ffffff">
                         <br />
                        We may use the email address you provided us to contact
                        your from time to time to provide you with important
                        information and required notices.
                      </font>
                    </p>
                    <p>
                      <font color="#ffffff">
                         <br />
                        Your registered wallet address also known as the public
                        key to facilitate cryptocurrency transactions is not
                        considered personal identifiable information and it
                        would be impossible to keep your wallet address
                        confidential as any blockchain transaction is publicly
                        available and may be looked up online.
                      </font>
                    </p>
                    <p>
                      <font color="#ffffff">
                         <br />
                        While we strive to keep our servers secure and handle
                        data confidentiality, you agree to indemnify us of any
                        security breach or mishandling of data. The software is
                        provided as-is without any liability, and there is no
                        guarantee that the app will work correctly or continue
                        to function similarly in the future.
                      </font>
                    </p>

                    <p>
                      <br />
                      <font color="#ffffff">
                        This Privacy Policy does not apply to linked websites. 
                        Please check your browser or system settings and consult
                        the privacy policy of any websites you visit.
                      </font>
                    </p>

                    <p>
                      <br />
                      <font color="#ffffff">
                        By using  our website or any associate app, you hereby
                        consent to our Privacy Policy and agree to its Terms and
                        Conditions which may be updated at any time.
                      </font>
                    </p>

                    <p> </p>

                    <p>
                      <font color="#ffffff">
                        If you have any questions or concerns Contact us 
                      </font>
                    </p>

                    <p>
                      <font color="#ffffff">captaincrytpo@protonmail.com</font>
                    </p>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                    <h4 class="wb-stl-pagetitle"> </h4>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="a18de6db278502f013bac3d22ca606e8"
            className="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div className="wb_content wb-layout-vertical"></div>
          </div>
        </div>
      </div>
      <div
        id="wb_footer_a18de6db260a00a166259f58cc4e2d52"
        className="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div className="wb_content wb-layout-vertical">
          <div
            id="a188dd9940c445fb48de0951260bf6da"
            className="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div className="wb_content wb-layout-vertical">
              <div
                id="a188dd9940c446b32be80ffd00af97c3"
                className="wb_element wb_text_element"
                data-plugin="TextArea"
                style={{ lineHeight: "normal", textAlign: "center" }}
              >
                <p>
                  <span style={{ color: "#ffffff" }}>
                    <span style={{ fontSize: "10px" }}>Copyright</span>
                    <span style={{ fontSize: "10px" }}>
                      2024 captaincrytpo.com - All Rights Reserved
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            id="wb_footer_c"
            className="wb_element"
            data-plugin="WB_Footer"
            style={{ textAlign: "center", width: "100%" }}
          >
            <div className="wb_footer"></div>
            <script type="text/javascript">
              {`$(function () {
                var footer = $(".wb_footer");
                var html = (footer.html() + "").replace(/^\s+|\s+$/g, "");
                if (!html) {
                  footer.parent().remove();
                  footer = $("#footer, #footer .wb_cont_inner");
                  footer.css({ height: "" });
                }
              });`}
            </script>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
