import React, { useState } from 'react';
import { Link } from "react-router-dom";
import "../assets/css/common-bundle.css@ts=20240226213632.css";
import "../assets/css/a188dd9953ad01c1fe6760c02b156fbf-bundle.css@ts=20240226213632.css";
import "../App.css";
function Main() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div id="wb_root" className="root wb-layout-vertical">
      <div className="wb_sbg"></div>
      <div
        id="wb_header_a188dd9953ad01c1fe6760c02b156fbf"
        className="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div className="wb_content wb-layout-vertical">
          <div
            id="a188dd9940c438fe407d1fea0abe36f6"
            className="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div className="wb_content wb-layout-horizontal">
              <div
                id="a188dd9940c4398c77b5c63b6e6b60fd"
                className="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div className="wb_content wb-layout-horizontal">
                  <div
                    id="a188dd9940c43a26af62d556ad5ffff4"
                    className="wb_element wb_text_element"
                    data-plugin="TextArea"
                    style={{ lineHeight: "normal" }}
                  >
                    <h5
                      className="wb-stl-subtitle"
                      style={{ textAlign: "center" }}
                    >
                      Captain Crytpo
                    </h5>
                  </div>
                </div>
              </div>
              <div
                id="a18b6705100e00761195196dd80c0372"
                className="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div className="wb_content wb-layout-vertical">
                  <div
                    id="a188dd9940c43bbe64de61a09b7ba7b4"
                    className="wb_element wb-menu wb-prevent-layout-click wb-menu-mobile"
                    data-plugin="Menu"
                  >
                    <button className="btn btn-default btn-collapser" onClick={toggleMenu}>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                    <ul className={`hmenu ${isMenuOpen ? 'open' : ''}`} dir="ltr">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="">
                        <Link to="/Our-Products">Our Products</Link>
                      </li>
                      <li className="">
                        <Link to="/About-Us" className='colortext'>About Us</Link>
                      </li>
                      <li className="">
                        <Link to="/Privacy-Policy">Privacy Policy</Link>
                      </li>
                      <li className="">
                        <Link to="/Contact-Us">Contact Us</Link>
                      </li>
                    </ul>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="wb_main_a188dd9953ad01c1fe6760c02b156fbf"
        className="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div class="wb_content wb-layout-vertical">
          <div
            id="a188dd9940c42acb5ef3f149f508827e"
            class="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div class="wb_content wb-layout-horizontal">
              <div
                id="a188dd9940c42b6b04682d2d46df4d3e"
                class="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div class="wb_content wb-layout-vertical"></div>
              </div>
              <div
                id="a18de63f4394004bcfad66eabe596708"
                class="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div class="wb_content wb-layout-vertical">
                  <div
                    id="a188dd9940c42cc13432cca3b6379be7"
                    class="wb_element wb_text_element"
                    data-plugin="TextArea"
                    style={{ lineHeight: "normal" }}
                  >
                    <h4 class="wb-stl-pagetitle">
                      <span style={{ color: "rgba(255, 255, 255, 1)" }}>
                        About Us
                      </span>
                    </h4>

                    <p> </p>

                    <p> </p>

                    <p> </p>

                    <p> </p>

                    <p> </p>

                    <p>
                      <font color="#ffffff">Captain Crytpo is </font>
                      <span style={{ color: "rgba(255, 255, 255, 1)" }}>
                        Everything Disruptive Technologies.  
                      </span>
                    </p>

                    <p> </p>

                    <p>
                      <span style={{ color: "rgba(255, 255, 255, 1)" }}>
                        Our team excels at finding, developing and building
                        techniques to profit from a quickly evolving
                        technological revolution that is happening right now.
                      </span>
                    </p>

                    <p> </p>

                    <p>
                      <span style={{ color: "rgba(255, 255, 255, 1)" }}>
                        Artificial Intelligence, Blockchain techonology and
                        cryptocurrency are redefining the financial landscape at
                        a blistering pace.  A client said to us that "things
                        were moving so swifty, the he didn't even see it
                        happening right in front of his eyes".
                      </span>
                    </p>

                    <p> </p>

                    <p>
                      <font color="#ffffff">
                        Simple basic understanding of some these revolutionary
                        developments and and minimal adoption of these changes
                        could have a profound impact on the financial status and
                        standard of living for you and generations to come.
                      </font>
                    </p>

                    <p> </p>

                    <p>
                      <font color="#ffffff">
                        There has been no other time in history, when the
                        average person has been in a position to be at the
                        forefront of an event so significant that will impact
                        the whole of the world population.  This is most likely
                        the only time in one's lifetime than an individual has
                        the opportunity to front run the biggest and
                        wealthiest corporations, institutions and individuals in
                        the world.
                      </font>
                    </p>

                    <p> </p>

                    <p>
                      <font color="#ffffff">You are still early.  </font>
                    </p>

                    <p> </p>

                    <p>
                      <font color="#ffffff">Captain Crytpo can provide </font>
                    </p>

                    <ul>
                      <li>
                        <font color="#ffffff">
                          speakers for your meetings and conferences
                        </font>
                      </li>
                      <li>
                        <font color="#ffffff">
                          individual guidance and educational services
                        </font>
                      </li>
                      <li>
                        <p>
                          <span style={{ color: "#ffffff" }}>
                            a team to evaluate your project and assist in
                            development and bringing to market
                          </span>
                        </p>
                      </li>
                    </ul>

                    <p> </p>

                    <p>
                      <font color="#ffffff">
                        Contact us and send us a quick note to get started.  Do
                        not let opportunity pass you by.  You deserve this.
                      </font>
                    </p>

                    <p> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="a188dd9940c433c62a51e9ed70f12696"
            class="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div class="wb_content wb-layout-vertical"></div>
          </div>
        </div>
      </div>
      <div
        id="wb_footer_a188dd9953ad01c1fe6760c02b156fbf"
        className="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div className="wb_content wb-layout-vertical">
          <div
            id="a188dd9940c445fb48de0951260bf6da"
            className="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div className="wb_content wb-layout-vertical">
              <div
                id="a188dd9940c446b32be80ffd00af97c3"
                className="wb_element wb_text_element"
                data-plugin="TextArea"
                style={{ lineHeight: "normal" }}
              >
                <p>
                  <span style={{ color: "#ffffff" }}>
                    <span style={{ fontSize: "10px" }}>Copyright</span>{" "}
                    <span style={{ fontSize: "10px" }}>
                      2024 captaincrytpo.com - All Rights Reserved
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            id="wb_footer_c"
            className="wb_element"
            data-plugin="WB_Footer"
            style={{ textAlign: "center", width: "100%" }}
          >
            <div className="wb_footer"></div>
            <script type="text/javascript">
              {`
                $(function () {
                  var footer = $(".wb_footer");
                  var html = (footer.html() + "").replace(/^\s+|\s+$/g, "");
                  if (!html) {
                    footer.parent().remove();
                    footer = $("#footer, #footer .wb_cont_inner");
                    footer.css({ height: "" });
                  }
                });
              `}
            </script>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
