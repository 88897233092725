import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import "../assets/css/common-bundle.css@ts=20240226213632.css";
import "../assets/css/a188dd9953ad035ab4072bfe8fdc901d-bundle.css@ts=20240226213632.css";
import "../App.css";
function Main() {
  const location = useLocation();
  const routeName = location.pathname;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div id="wb_root" class="root wb-layout-vertical">
      <div class="wb_sbg"></div>
      <div
        id="wb_header_a188dd9953ad035ab4072bfe8fdc901d"
        class="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div class="wb_content wb-layout-vertical">
          <div
            id="a188dd9940c438fe407d1fea0abe36f6"
            class="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div class="wb_content wb-layout-horizontal">
              <div
                id="a188dd9940c4398c77b5c63b6e6b60fd"
                class="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div class="wb_content wb-layout-horizontal">
                  <div
                    id="a188dd9940c43a26af62d556ad5ffff4"
                    class="wb_element wb_text_element"
                    data-plugin="TextArea"
                    style={{"lineHeight": "normal"}}
                  >
                    <h5 class="wb-stl-subtitle" style={{"text-align": "center"}}>
                      Captain Crytpo
                    </h5>
                  </div>
                </div>
              </div>
              <div
                id="a18b6705100e00761195196dd80c0372"
                class="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div class="wb_content wb-layout-vertical">
                  <div
                    id="a188dd9940c43bbe64de61a09b7ba7b4"
                    class="wb_element wb-menu wb-prevent-layout-click wb-menu-mobile"
                    data-plugin="Menu"
                  >
                    <button className="btn btn-default btn-collapser" onClick={toggleMenu}>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                    <ul className={`hmenu ${isMenuOpen ? 'open' : ''}`} dir="ltr">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/Our-Products" className='colortext'>Our Products</Link>
                      </li>
                      <li className="">
                        <Link to="/About-Us">About Us</Link>
                      </li>
                      <li className="">
                        <Link to="/Privacy-Policy">Privacy Policy</Link>
                      </li>
                      <li className="">
                        <Link to="/Contact-Us">Contact Us</Link>
                      </li>
                    </ul>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="wb_main_a188dd9953ad035ab4072bfe8fdc901d"
        class="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div class="wb_content wb-layout-vertical">
          <div
            id="a188dd9940c4049ced27339cf1b3f740"
            class="wb_element wb_text_element"
            data-plugin="TextArea"
            style={{"lineHeight": "normal"}}
          >
            <h4 class="wb-stl-pagetitle">Our products</h4>
          </div>
          <div
            id="a188dd9940c402c83a8c87402c0c4347"
            class="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div class="wb_content wb-layout-horizontal">
              <div
                id="a188dd9940c4032ac44e5d291e4ad79d"
                class="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div class="wb_content wb-layout-vertical"></div>
              </div>
              <div
                id="a18ddc3b94440012268d3352ab72d388"
                class="wb_element wb-layout-element"
                data-plugin="LayoutElement"
              >
                <div class="wb_content wb-layout-horizontal">
                  <div
                    id="a18ddc3b944c007d757aa96def994c82"
                    class="wb_element wb-layout-element"
                    data-plugin="LayoutElement"
                  >
                    <div class="wb_content wb-layout-vertical">
                      <div
                        id="a18ddc3b945400529fd2750d02acc76d"
                        class="wb_element wb_element_picture"
                        data-plugin="Picture"
                        title=""
                      >
                        <div class="wb_picture_wrap">
                          <div class="wb-picture-wrapper">
                            <Link to="/Contact-Us">
                              <img
                                alt=""
                                src="../gallery_gen/cfefd16e8ba4d7e88070e926f60b3562_fit.jpg@ts=1708976192"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        id="a18ddc4d2b8100ae4b4e0426c9343fe5"
                        class="wb_element wb-layout-element"
                        data-plugin="LayoutElement"
                      >
                        <div class="wb_content wb-layout-vertical">
                          <div
                            id="a18ddc3b946700752638ae0aacaf0cc4"
                            class="wb_element wb_text_element"
                            data-plugin="TextArea"
                            style={{"lineHeight": "normal"}}
                          >
                            <p class="wb-stl-normal" style={{"textAlign": "center"}}>
                                 
                              <span style={{"fontSize": "18px"}}>
                                <strong>
                                  <span style={{"color": "#0c0b0b"}}>EDUCATION</span>
                                </strong>
                              </span>
                            </p>

                            <p class="wb-stl-normal" style={{"textAlign": "center"}}>
                               
                            </p>

                            <p class="wb-stl-normal">
                              Cryptocurrency is the future.  Our team of
                              professional experts can get you started.  It's
                              time.  Do not let this once in a lifetime
                              opportunity pass you by.
                            </p>

                            <p class="wb-stl-normal"> </p>

                            <p class="wb-stl-normal"> We can teach you about</p>

                            <ul>
                              <li class="wb-stl-normal">SELF-CUSTODY </li>
                              <li class="wb-stl-normal">CRYPTO WALLETS</li>
                              <li class="wb-stl-normal">
                                BLOCKCHAIN TECHNOLOGY
                              </li>
                              <li class="wb-stl-normal">DECENTRALIZATION</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="a18ddc3b94a200eb2e36762e78c90503"
                    class="wb_element wb-layout-element"
                    data-plugin="LayoutElement"
                  >
                    <div class="wb_content wb-layout-vertical">
                      <div
                        id="a18ddc3b94a900e21206d13bdd5b0e94"
                        class="wb_element wb_element_picture"
                        data-plugin="Picture"
                        title=""
                      >
                        <div class="wb_picture_wrap">
                          <div class="wb-picture-wrapper">
                            <Link to="/Contact-Us">
                              <img
                                alt=""
                                src="../gallery_gen/12e32855d16834a0a292658f5d28c77c_736x736_fit.png@ts=1708976192"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        id="a18ddc3b94b500da465789801d7e7fd2"
                        class="wb_element wb_text_element"
                        data-plugin="TextArea"
                        style={{"line-height": "normal"}}
                      >
                        <p class="wb-stl-normal" style={{"text-align": "center"}}>
                          <span style={{"font-size": "18px"}}>
                            <span style={{"color": "#0c0b0b"}}>
                              <strong>GAMING</strong>
                            </span>
                          </span>
                        </p>

                        <p class="wb-stl-normal" style={{"textAlign": "center"}}>
                           
                        </p>

                        <p class="wb-stl-normal" style={{"textAlign": "center"}}>
                          <span style={{"fontSize": "18px"}}>
                            <span style={{"color": "#0c0b0b"}}>
                              <strong>
                                Introducing our latest game...1 in 100
                              </strong>
                            </span>
                          </span>
                        </p>

                        <p> </p>

                        <p class="wb-stl-normal">
                          Increase your DOGE crypto holdings with this mobile
                          game.  The odds are in your favor.  There is a winner
                          every 100 plays.  <strong>1 in 100.</strong>
                        </p>

                        <p> </p>

                        <p>
                          <span style={{"color": "#ffffff"}}>
                            Not quite a slot machine...Not quite a lottery...
                          </span>
                        </p>

                        <p> </p>

                        <p>
                          <br />
                          <span style={{"color": "#ffffff"}}>
                            The only game where you can combine luck &amp;
                            timing
                          </span>
                        </p>

                        <p> </p>

                        <p> </p>

                        <p class="wb-stl-normal">
                          Play 24 hrs./day ... 7 days/week
                        </p>

                        <p> </p>

                        <p> </p>

                        <p class="wb-stl-normal">
                          Winning is more than luck and odds. You can time it
                        </p>

                        <p> </p>

                        <p> </p>

                        <p class="wb-stl-normal" style={{"textAlign": "center"}}>
                          Available on Google Play Store &amp; Apple Store
                        </p>

                        <p class="wb-stl-normal" style={{"textAlign": "center"}}>
                           
                        </p>

                        <p> </p>

                        <p class="wb-stl-normal"> </p>

                        <p> </p>
                      </div>
                    </div>
                  </div>
                  <div
                    id="a18ddc3b94cb009bb048629a1e69825e"
                    class="wb_element wb-layout-element"
                    data-plugin="LayoutElement"
                  >
                    <div class="wb_content wb-layout-vertical">
                      <div
                        id="a18ddc3b94d2005c78c0f18990fe56d1"
                        class="wb_element wb_element_picture"
                        data-plugin="Picture"
                        title=""
                      >
                        <div class="wb_picture_wrap">
                          <div class="wb-picture-wrapper">
                            <Link to="/Contact-Us">
                              <img
                                alt=""
                                src="../gallery_gen/72352d8848c6946dbc55e0246995b882_fit.webp@ts=1708976192"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        id="a18ddc3b94dd00f9661cb33280a89704"
                        class="wb_element wb_text_element"
                        data-plugin="TextArea"
                        style={{"lineHeight": "normal"}}
                      ></div>
                      <div
                        id="a18ddc3b94ea00a86ff2241139175153"
                        class="wb_element wb_text_element"
                        data-plugin="TextArea"
                        style={{"lineHeight": "normal"}}
                      >
                        <p class="wb-stl-normal" style={{"textAlign": "center"}}>
                          <strong>
                            <span style={{"color": "#0c0b0b"}}>
                              BUSINESS DEVELOPMENT
                            </span>
                          </strong>
                        </p>

                        <p class="wb-stl-normal" style={{"textAlign": "center"}}>
                          <span style={{"fontSize": "18px"}}>
                            <span style={{"color": "#0c0b0b"}}>
                              Have An Idea That Involves Crypto?
                            </span>
                          </span>
                        </p>

                        <p> </p>

                        <p class="wb-stl-normal">
                          Get in touch with us at Captain Crytpo and one of our
                          professional team members can assist you.  Our team is
                          capable of assisting you with everything from
                          development, to launch, to marketing.
                        </p>

                        <p> </p>

                        <p> </p>

                        <p class="wb-stl-normal">
                          Your success is our success.  Start building today.
                        </p>

                        <p> </p>

                        <p> </p>

                        <p> </p>

                        <p> </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div
        id="wb_footer_a188dd9953ad035ab4072bfe8fdc901d"
        class="wb_element wb-layout-element"
        data-plugin="LayoutElement"
      >
        <div class="wb_content wb-layout-vertical">
          <div
            id="a188dd9940c445fb48de0951260bf6da"
            class="wb_element wb-layout-element"
            data-plugin="LayoutElement"
          >
            <div class="wb_content wb-layout-vertical">
              <div
                id="a188dd9940c446b32be80ffd00af97c3"
                class="wb_element wb_text_element"
                data-plugin="TextArea"
                style={{"line-height": "normal"}}>
                <p>
                  <span style={{"color": "#ffffff"}}>
                    <span style={{"fontSize": "10px"}}>Copyright</span> 
                    <span style={{"fontSize": "10px"}}>
                      2024 captaincrytpo.com - All Rights Reserved
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            id="wb_footer_c"
            className="wb_element"
            data-plugin="WB_Footer"
            style={{ textAlign: "center", width: "100%" }}
          >
            <div className="wb_footer"></div>
            <script type="text/javascript">
              {`$(function () {
                var footer = $(".wb_footer");
                var html = (footer.html() + "").replace(/^\s+|\s+$/g, "");
                if (!html) {
                  footer.parent().remove();
                  footer = $("#footer, #footer .wb_cont_inner");
                  footer.css({ height: "" });
                }
              });`}
            </script>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
